<template>
  <v-container style="max-width: 100%">
    <v-tabs v-model="tab" background-color="grey lighten-2">
      <v-tab key="next">Aufträge ({{ assignments.length }})</v-tab>
      <v-tab key="done">Abgeschlossene Aufträge</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="next">
        <v-card class="mt-10">
          <v-card-title class="headline">
            <span>Aufträge ({{ assignments.length }})</span>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-if="role != 'worker'" v-on="on" style="position: absolute; right: 20px; top: 7px" color="success"
                  class="float-right" fab @click="
                    openNewAssignment()
                    ">
                  <i class="material-icons">add</i>
                </v-btn>
              </template>
              <span>Neuen Auftrag erstellen</span>
            </v-tooltip>
            <!--  <v-tooltip left v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  style="position: absolute;right: 20px;top: 7px;"
                  color="success"
                  class="float-right"
                  fab
                  @click="$router.push('/arbeitsnachweis/new')"
                >
                  <i class="material-icons">add</i>
                </v-btn>
              </template>
              <span>Neuen Auftrag erstellen</span>
            </v-tooltip>-->
          </v-card-title>
          <v-card-text>
            <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
              <v-text-field v-model="search2" append-icon="search" label="Suche"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table :headers="headers" :items="assignments" class="row-pointer" @click:row="handleClick"
                :items-per-page="-1" sort-by="customername" :search="search2">
                <template v-slot:item.updated_at="{ item }">{{
                  $formatDateTime(item.updated_at)
                }}</template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status == '0'" class="orange-highlight">Erstellt</span>
                  <span v-if="item.status == '1'" class="orange-highlight">In Bearbeitung</span>
                  <span v-if="item.status == '2'" class="darkred-highlight">Übermittelt</span>
                  <span v-if="item.status == '3'" class="green-highlight">Abgeschlossen</span>
                </template>
                <template v-slot:item.standortname="{ item }">
                  <span v-if="item.standortname">{{ item.standortname }}</span>
                  <span v-if="!item.standortname && item.info">{{
                    JSON.parse(item.info).address
                  }}</span>
                </template>
                <template v-slot:item.type="{ item }">
                  <v-chip color="purple" dark v-if="item.type == 'Service'">{{
                    item.type
                  }}</v-chip>
                  <v-chip color="cyan" dark v-if="item.type == 'Wartung'">{{
                    item.type
                  }}</v-chip>
                  <v-chip color="#9E9D24" dark v-if="item.type == 'Montage'">{{
                    item.type
                  }}</v-chip>
                </template>
                <template v-slot:item.date="{ item }">{{
                  $formatDate(item.date)
                }}</template>
                <template v-slot:item.action="{ item }">
                  <div class="text-center inline-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="handleClick(item)" fab small color="primary">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Auftrag anzeigen</span>
                    </v-tooltip>
                    <v-tooltip left v-if="role !== 'worker'">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="
                          confirmDeleteItem(item.id, item.assignmentnumber)
                          " color="error" small fab>
                          <i class="material-icons">delete</i>
                        </v-btn>
                      </template>
                      <span>Auftrag löschen</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="done">
        <v-card class="mt-10">
          <v-card-title class="headline">
            <span>Abgeschlossene Aufträge</span>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field v-if="role != 'worker'" v-model="search" append-icon="search" @keydown.enter="startSearch"
                label="Suche"></v-text-field>
              <v-btn color="primary" v-if="role != 'worker'" @click="startSearch">Suchen</v-btn>
            </v-col>
            <v-data-table :headers="headersdones" class="row-pointer" :items="doneassignments" :items-per-page="25"
              @click:row="handleClick" sort-by="customername" :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }">
              <template v-slot:item.status="{ item }">
                <span v-if="item.status == '0'" class="orange-highlight">Erstellt</span>
                <span v-if="item.status == '1'" class="orange-highlight">In Bearbeitung</span>
                <span v-if="item.status == '2'" class="darkred-highlight">Übermittelt</span>
                <span v-if="item.status == '3'" class="green-highlight">Abgeschlossen</span>
              </template>
              <template v-slot:item.customername="{ item }">
                <span v-if="item.customername">{{ item.customername }}</span>
                <span v-if="!item.customername && item.info">{{
                  JSON.parse(item.info).customername
                }}</span>
              </template>
              <template v-slot:item.type="{ item }">
                <v-chip color="purple" dark v-if="item.type == 'Service'">{{
                  item.type
                }}</v-chip>
                <v-chip color="cyan" dark v-if="item.type == 'Wartung'">{{
                  item.type
                }}</v-chip>
                <v-chip color="#9E9D24" dark v-if="item.type == 'Montage'">{{
                  item.type
                }}</v-chip>
              </template>
              <template v-slot:item.standortname="{ item }">
                <span v-if="item.standortname">{{ item.standortname }}</span>
                <span v-if="!item.standortname && item.info">{{
                  JSON.parse(item.info).address
                }}</span>
              </template>
              <template v-slot:item.finalized="{ item }">{{
                $formatDateTime(item.finalized)
              }}</template>
              <template v-slot:item.date="{ item }">{{
                $formatDate(item.date)
              }}</template>
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left v-if="role !== 'worker'">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="
                        confirmDeleteItem(item.id, item.assignmentnumber)
                        " color="error" small fab>
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Auftrag löschen</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-layout row justify-center>
      <v-dialog v-model="showNewAssignment" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <span v-if="edit" class="headline">Auftrag bearbeiten</span>
            <span v-else class="headline">Neuen Auftrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="newAssignment.date" label="Datum*" prepend-icon="event" readonly v-on="on"
                          required :rules="[rules.required]"></v-text-field>
                      </template>
                      <v-date-picker v-model="newAssignment.date" no-title first-day-of-week="1" scrollable
                        @input="menu = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field label="Auftragsnummer*" v-model="newAssignment.assignmentnumber" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <v-select label="Typ" v-model="newAssignment.type" :items="['Service', 'Wartung', 'Montage']" required
                      :rules="[rules.required]"></v-select>
                  </v-flex>
                  <div class="full-width layout wrap" v-if="newAssignment.created_by === userid">
                    <v-flex xs12 sm12 md12 v-if="newAssignment.info">
                      <v-text-field label="Kundenname" v-model="newAssignment.info.customername"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12 v-if="newAssignment.info">
                      <v-text-field label="Adresse" v-model="newAssignment.info.address"></v-text-field>
                    </v-flex>
                  </div>
                  <div class="full-width layout wrap" v-else>
                    <v-flex xs12 sm6 md6 v-if="newAssignment.info">
                      <div>
                        <b>Kundenname</b>
                      </div>
                      <div>{{ newAssignment.info.customername }}</div>
                    </v-flex>
                    <v-flex xs12 sm6 md6 v-if="newAssignment.info">
                      <div>
                        <b>Adresse</b>
                      </div>
                      <div>{{ newAssignment.info.address }}</div>
                    </v-flex>
                    <!--  <v-flex xs12 sm6 md6 v-if="newAssignment.info">
                      <div>
                        <b>Betreff</b>
                      </div>
                      <div>{{newAssignment.subject}}</div>
                    </v-flex>
                    <v-flex xs12 sm6 md6 v-if="newAssignment.info">
                      <div>
                        <b>Typ</b>
                      </div>
                      <div>{{newAssignment.info.type}}</div>
                    </v-flex>-->
                  </div>
                  <v-flex xs12 sm6 md6 v-if="role !== 'worker'">
                    <v-select label="Kunde*" v-model="newAssignment.customerid" item-text="name" item-value="id"
                      :items="customers" @change="changeCustomer()" required :rules="[rules.required]"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 v-if="role !== 'worker'">
                    <v-select v-model="newAssignment.standortid" item-text="name" item-value="id" :items="standorte"
                      :disabled="!standorte.length" label="Standort" required :rules="[rules.required]"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 v-else>
                    <v-text-field v-model="info.customername" label="Kundenname*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 v-if="role !== 'worker'">
                    <v-select clearable multiple v-model="newAssignment.userids" :items="users" item-text="name"
                      item-value="id" label="Mitarbeiter"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 v-else>
                    <v-text-field v-model="info.address" label="Adresse*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-select clearable multiple v-model="newAssignment.plids" :items="leiters" item-text="name"
                      item-value="id" label="Projektleiter" required :rules="[rules.requiredarray]"></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea outlined v-model="newAssignment.comment" label="Kommentar"></v-textarea>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-alert type="error" :value="numberExsists">Die von ihnen gewähle Auftragsnummer exsistiert bereits. Bitte
                wählen Sie eine andere Auftragsnummer</v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="addAssignment()" v-if="edit">Aktualisieren</v-btn>
            <v-btn color="success" text @click="addAssignment(1)" v-else>Erstellen</v-btn>
            <v-btn text color="error" @click="
              showNewAssignment = false;
            clearAssignment();
            ">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Auftrag löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie den Auftrag
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showNewAssignment: false,
      menu: false,
      confirm: false,
      numberExsists: false,
      tab: "",
      search: "",
      search2: "",
      info: {},
      edit: false,
      deleteName: "",
      deleteId: "",
      deleteMode: "",
      assignments: [],
      doneassignments: [],
      users: [],
      leiters: [],
      customers: [],
      standorte: [],
      newAssignment: {},
      headersdones: [
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Auftragsnummer",
          align: "left",
          sortable: true,
          value: "assignmentnumber",
        },
        {
          text: "Auftragstyp",
          align: "center",
          sortable: true,
          value: "type",
        },
        {
          text: "Kundennamen",
          align: "left",
          sortable: true,
          value: "customername",
        },
        {
          text: "Mitarbeiter",
          align: "left",
          sortable: true,
          value: "usernames",
        },
        {
          text: "Projektleiter",
          align: "left",
          sortable: true,
          value: "plnames",
        },
        {
          text: "Kommentar",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Erstellt von",
          align: "left",
          sortable: false,
          value: "creator",
        },
        {
          text: "Abgeschlossen am",
          align: "left",
          value: "finalized",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headers: [
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Auftragsnummer",
          align: "left",
          sortable: true,
          value: "assignmentnumber",
        },
        {
          text: "Auftragstyp",
          align: "center",
          sortable: true,
          value: "type",
        },
        {
          text: "Kundennamen",
          align: "left",
          sortable: true,
          value: "customername",
        },
        {
          text: "Mitarbeiter",
          align: "left",
          sortable: true,
          value: "usernames",
        },
        {
          text: "Projektleiter",
          align: "left",
          sortable: true,
          value: "plnames",
        },
        {
          text: "Kommentar",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Erstellt von",
          align: "left",
          sortable: false,
          value: "creator",
        },
        {
          text: "Geändert am",
          align: "left",
          value: "updated_at",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Erforderlich.",
        requiredarray: (value) => !!value.length || "Erforderlich.",
      },
    };
  },
  computed: {
    userid() {
      return this.$store.getters.user.id;
    },
    role() {
      return this.$store.getters.user.role;
    },
  },
  mounted() {
    this.clearAssignment();
    this.getAssignments();
    this.tab = "next";
  },
  methods: {
    startSearch() {
      const val = this.search;
      if (!val) {
        this.items = [];
        return;
      }
      this.isLoading = true;
      const self = this;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "findAssignments.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            self.doneassignments = response.data.data;
            if (!response.data.data.length) {
              const msg =
                "Ihre Suche ergab keinen Treffer. Bitte versuchen Sie es mit einem anderen Suchbegriff.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    editAssignment(item) {
      this.newAssignment = item;
      let ids;
      let userids = [];
      if (item.userids) {
        if (Array.isArray(item.userids)) {
          ids = item.userids;
        } else {
          ids = JSON.parse(item.userids);
        }

        ids.forEach((id) => {
          userids.push(id + "");
        });
      }
      this.newAssignment["userids"] = userids;
      let plids;
      let leiters = [];
      if (item.plids) {
        if (Array.isArray(item.plids)) {
          plids = item.plids;
        } else {
          plids = JSON.parse(item.plids);
        }

        plids.forEach((id) => {
          leiters.push(id + "");
        });
      }
      if (typeof item.info === "string" || item.info instanceof String) {
        if (item.info === "") {
          this.newAssignment.info = {};
        } else {
          this.newAssignment.info = JSON.parse(item.info);
        }
      } else {
        this.newAssignment.info = item.info;
      }
      this.newAssignment["plids"] = leiters;
      if (this.role !== "worker") {
        this.getStandorte(item.customerid);
      }
      this.edit = true;
      this.showNewAssignment = true;
    },
    addAssignment(code) {
      this.numberExsists = false;
      if (this.$refs.form.validate()) {
        let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
        if (newAssignment.customerid && newAssignment.customerid !== "0") {
          newAssignment["customername"] = this.findCustomerName(newAssignment);
        }
        newAssignment["creator"] = this.$store.getters.user.name;
        const userids = newAssignment["userids"];
        let usernames = [];
        let ids = [];
        if (userids) {
          userids.forEach((id) => {
            const user = this.users.find((u) => {
              return u.id === id;
            });
            if (user && user.name && user.id) {
              usernames.push(user.name);
              ids.push(parseInt(user.id));
            }
          });
        }
        newAssignment["usernames"] = usernames.join(", ");
        newAssignment["userids"] = ids;

        const plids = newAssignment["plids"];
        let plnames = [];
        let pids = [];
        if (plids) {
          plids.forEach((id) => {
            const pl = this.leiters.find((u) => {
              return u.id === id;
            });
            if (pl && pl.name && pl.id) {
              plnames.push(pl.name);
              pids.push(parseInt(pl.id));
            }
          });
        }
        newAssignment["plnames"] = plnames.join(", ");
        newAssignment["plids"] = pids;
        const date = Math.round(new Date(newAssignment.date).getTime() / 1000);
        newAssignment["time"] = date;
        let formData = new FormData();
        if (!this.edit) {
          newAssignment["status"] = 1;
        }
        formData.append("assignment", JSON.stringify(newAssignment));
        formData.append("edit", this.edit);
        if (code === 1) {
          formData.append("info", JSON.stringify(this.info));
        } else {
          formData.append("info", JSON.stringify(newAssignment.info));
        }
        this.$http({
          method: "post",
          url: "saveAssignment.php",
          data: formData,
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (
              response.data &&
              response.data.success === false &&
              response.data.authorization
            ) {
              const msg =
                "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
              this.confirm = false;
              return;
            }
            if (response.data && response.data.success) {
              let msg = "";
              if (this.edit) {
                let assignments = this.assignments;
                let index = -1;
                let assignment = assignments.forEach((a, i) => {
                  if (a.id === newAssignment.id) {
                    index = i;
                  }
                });
                if (index > -1) {
                  assignments[index] = newAssignment;
                  this.assignments = assignments;
                  msg = "Der Auftrag wurde erfolgreich aktualisiert";
                }
              } else {
                newAssignment["id"] = response.data.id;
                this.assignments.push(newAssignment);
                msg = "Der Auftrag wurde erfolgreich erstellt";
              }
              this.clearAssignment();
              this.edit = false;
              this.showNewAssignment = false;
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            } else {
              if (response.data.numberExsists) {
                this.numberExsists = true;
              } else {
                const msg =
                  "Der Auftrag konnten nicht erstellt werden. Bitte versuchen Sie es erneut.";
                const color = "error";
                this.$store.dispatch("snackbar", { msg, color });
              }
            }
          })
          .catch((error) => {
            const response = error.response.data;
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
              return;
            }
            this.$router.push("/");
          });
      }
    },
    openNewAssignment() {
      this.showNewAssignment = true;
      this.edit = false;
      if(this.role == "pl"){
        this.newAssignment.plids = [this.userid + ""];
      }
    },
    handleClick(item) {
      this.$router.push("/assignment/" + item.id);
    },
    finalizeAssignment(id) {
      let formData = new FormData();
      if (!id) {
        alert("Es ist ein fehler aufgetretten");
        return;
      }
      formData.append("id", id);
      formData.append("status", 3);
      this.$http({
        method: "post",
        url: "changeAssignmentStatus.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            const msg = "Der Auftrag wurde erfolgreich abgeschlossen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.assignments.forEach((a, i) => {
              if (a.id === id) {
                this.assignments.splice(i, 1);
              }
            });
            /*   this.assignments.forEach((item, i) => {
              if (item.id === id) {
                item.status = "3";
                this.doneassignments.push(item);
                this.assignments.splice(i, 1);
              }
            }); */
          } else {
            const msg =
              "Die Auftrag konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Die Auftrag konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    clearAssignment() {
      this.newAssignment = {
        date: this.$formatDateISO(new Date()),
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        plids: "",
        plnames: "",
        info: "",
        userids: "",
        userames: "",
        comment: "",
      };
    },
    confirmDeleteItem(id, name) {
      this.deleteName = name;
      this.deleteId = id;
      this.confirm = true;
    },
    findCustomerName(item) {
      if (item.customerid && this.customers) {
        const customer = this.customers.find((c) => {
          return c.id === item.customerid;
        });
        if (customer && customer.name) {
          return customer.name;
        }
      }
    },
    getAssignments() {
      /*   this.$http
        .get("assignment/assignments") */
      this.$http({
        method: "post",
        url: "getAssignments.php",
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data) {
            if (response.data.data) {
              response.data.data.forEach((a) => {
                if (!a.customername && a.info) {
                  a.customername = JSON.parse(a.info).customername;
                }
                /* if (a.status === "3") {
                  this.doneassignments.push(a);
                } else { */
                this.assignments.push(a);
                // }
              });
            }
            if (response.data.dones) {
              this.doneassignments = response.data.dones;
            }
            if (response.data.users) {
              this.users = response.data.users;
            }
            if (response.data.leiters) {
              this.leiters = response.data.leiters;
            }
            if (response.data.customers) {
              this.customers = response.data.customers;
            }
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    changeCustomer() {
      const id = this.newAssignment && this.newAssignment.customerid;
      if (id) {
        this.getStandorte(id);
      }
    },
    getStandorte(id) {
      let formData = new FormData();
      formData.append("id", id);
      this.$http({
        method: "post",
        url: "getStandorte.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.standorte = response.data.data;
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    deleteItem() {
      let formData = new FormData();
      formData.append("id", this.deleteId);
      let url = "";
      this.$http({
        method: "post",
        url: "deleteAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            this.assignments.forEach((a, i) => {
              if (a.id === this.deleteId) {
                this.assignments.splice(i, 1);
                this.deleteName = "";
                this.deleteId = "";
                this.confirm = false;
              }
            });
            const msg = "Der Auftrag wurde erfolgreich gelöscht.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
  },
};
</script>